<template>
  <v-card>
    <v-card class="mb-3">
      <v-card-title>Manage Mutation</v-card-title>
      <v-card-text>
        <v-alert dense type="info" v-if="message"> {{ message }} </v-alert>
        <v-form ref="form" @submit.prevent="onSend">
          <v-select
            :items="getUserSelect"
            label="User"
            item-text="label"
            item-value="value"
            v-model="user_id"
          ></v-select>
          <v-text-field label="Title" v-model="name" required></v-text-field>
          <v-text-field label="Total" v-model="total" required></v-text-field>
          <v-radio-group v-model="type" row>
            <v-radio label="Isi Saldo" value="plus" color="primary"></v-radio>
            <v-radio label="Kurangi Saldo" value="minus" color="error"></v-radio>
          </v-radio-group>
          <v-textarea label="Note" v-model="description" placeholder="This will appear in detail mutation"></v-textarea>
          <v-file-input multiple label="Attach Image" v-model="image"></v-file-input>
          <v-btn color="primary" class="mr-4" type="submit"> Create </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <CardUserBalance />
  </v-card>
</template>

<script>
import CardUserBalance from '../../../components/CardUserBalance.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  components: {
    CardUserBalance
  },
  data: () => ({
    user_id: '',
    type: '',
    name: '',
    description: '',
    total: '',
    image: null
  }),
  computed: {
    ...mapState({
      message: (state) => state.mutation.message
    }),
    ...mapGetters('user', ['getUserSelect'])
  },
  methods: {
    ...mapActions('user', ['getAllUser']),
    ...mapActions('mutation', ['createMutation']),
    onSend() {
      const { type, name, description, total, image, user_id } = this
      this.createMutation({
        user_id,
        type,
        name,
        description,
        total,
        image
      })
    }
  },
  mounted() {
    this.getAllUser()
  }
}
</script>
<template>
  <v-card>
    <card-bill :total="formatNumberRupiah(bill.total)" :status="status" :title="bill.name" />
    <v-card class="mb-3">
      <v-card-title>Update Bill</v-card-title>
      <v-card-text>
        <v-alert border="top" colored-border type="info" elevation="2" v-if="message">{{ message }} </v-alert>
        <v-text-field label="User" :value="bill.user.name" readonly></v-text-field>
        <v-form ref="form" @submit.prevent="onSend">
          <v-text-field
            label="Notes"
            v-model="note"
            placeholder="This will appear in detail bill (home)"
          ></v-text-field>
          <v-file-input multiple label="Attach Image" v-model="image"></v-file-input>
          <v-btn color="primary" class="mr-4" v-if="status == 'unpaid'" type="submit"> PAY </v-btn>
          <v-btn color="error" class="mr-4" v-else type="submit"> UNPAY </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CardBill from '../../../components/CardBill.vue'
import { formatNumberRupiah } from '../../../helpers/helper'
export default {
  components: {
    CardBill
  },
  data: () => ({
    note: '',
    image: null,
    type: ''
  }),
  computed: {
    id() {
      return this.$route.params.id
    },
    ...mapState({
      bill: (state) => state.bill.bill,
      message: (state) => state.bill.message
    }),
    status() {
      return this.bill.paid_at != null ? 'paid' : 'unpaid'
    },
    payStatus() {
      return this.status == 'unpaid' ? 'pay' : 'unpay'
    }
  },
  methods: {
    ...mapActions('bill', ['getBill', 'updateBill']),
    formatNumberRupiah,
    onSend() {
      this.updateBill({
        id: this.id,
        note: this.note,
        image: this.image,
        type: this.payStatus
      })
    }
  },
  mounted() {
    this.getBill(this.id)
  }
}
</script>
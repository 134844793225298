<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-select
          :items="getUserSelect"
          v-model="user_id"
          label="Name"
          item-text="label"
          item-value="value"
          @change="refreshBills"
          solo
        ></v-select>
      </v-col>
      <v-col v-for="(item, i) in bills" :key="i" cols="12">
        <CardBill :status="paidStatus(item.paid_at)" :title="item.name" :total="item.total" :user="item.user.name" :id="item.id" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CardBill from '../../../components/CardBillAdmin.vue'

export default {
  name: 'Home',

  components: {
    CardBill
  },

  data: () => ({
    user_id: ''
  }),
  computed: {
    ...mapState({
      bills: (state) => state.bill.bills
    }),
    ...mapGetters('user', ['getUserSelect'])
  },
  methods: {
    ...mapActions('bill', ['getAllBill']),
    ...mapActions('user', ['getAllUser']),
    refreshBills() {
      this.getAllBill({ user_id: this.user_id })
    },
    paidStatus(paid_at) {
      return paid_at != null ? 'paid' : 'unpaid'
    }
  },
  mounted() {
    this.getAllUser()
    this.refreshBills()
  }
}
</script>

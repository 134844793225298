<template>
  <div>
    <v-list flat subheader>
      <v-subheader>Users</v-subheader>

      <v-list-item-group v-model="selectedUsers" multiple active-class="">
        <v-list-item v-for="(user, index) in users" :key="index">
          <template v-slot:default="{ active }">
            <v-list-item-action>
              <v-checkbox :input-value="active"></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ user.name }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <template v-for="(user, index) in selectedUsers">
      <v-text-field
        :label="users[user].name"
        :key="index"
        v-model="userPortion[user]"
        required
        type="number"
      ></v-text-field>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
  methods: {
    ...mapActions('user', ['getAllUser'])
  },
  computed: {
    ...mapState({
      portion: (state) => state.invoice.formCreate.portion,
      users: (state) => state.user.users
    }),
    ...mapGetters('invoice', ['getBilledUserPortion']),
    selectedUsers: {
      get() {
        return this.portion.selectedUser
      },
      set(value) {
        this.$store.commit('invoice/setPortionSelectedUser', value)
      }
    },
    userPortion: {
      get() {
        return this.portion.userPortion
      },
      set(value) {
        this.$store.commit('invoice/setPortionUserPortion', value)
      }
    }
  },
  mounted() {
    this.getAllUser()
  }
}
</script>
<template>
  <v-card>
    <v-card-title>Users Balance</v-card-title>
    <v-card-text>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <v-select
              :items="getUserSelect"
              item-value="value"
              item-text="label"
              label="Balance"
              @change="getMutation"
              v-model="user_id"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12"> <CardBalance v-if="user" /> </v-col>
          <v-col v-for="(item, index) in mutations" :key="index" cols="12">
            <CardMutation :mutation="item" />
          </v-col>
        </v-row> </v-container
    ></v-card-text>
  </v-card>
</template>

<script>
import CardMutation from './CardMutation.vue'
import CardBalance from './CardBalance.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    CardMutation,
    CardBalance
  },
  data: () => ({
    user_id: ''
  }),
  methods: {
    ...mapActions('mutation', ['getAllMutation']),
    ...mapActions('user', ['getUser']),
    getMutation() {
      if (this.user_id != '') this.getUser(this.user_id)
      this.getAllMutation({ user_id: this.user_id })
    }
  },

  computed: {
    ...mapState({
      mutations: (state) => state.mutation.mutations,
      user: (state) => state.user.user
    }),
    ...mapGetters('user', ['getUserSelect'])
  },

  mounted() {
    this.getMutation()
  }
}
</script>
<template>
  <v-card class="mx-auto" max-width="500">
    <v-list>
      <v-list-group v-for="item in items" :key="item.title" v-model="item.active" :prepend-icon="item.action" no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-for="child in item.items" :key="child.title" :to="child.to">
          <v-list-item-content>
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    items: [
      {
        action: 'mdi-receipt',
        items: [
          { title: 'Create Invoice', to: '/admin/invoice/create' },
          { title: 'List Invoice', to: '/admin/invoice/list' },
          { title: 'List Bill', to: '/admin/invoice/bill' }
        ],
        title: 'Invoices'
      },
      {
        action: 'mdi-format-list-bulleted-type',
        items: [{ title: 'Manage Type', to: '/admin/type' }],
        title: 'Type'
      },
      {
        action: 'mdi-cash-multiple',
        items: [{ title: 'Balance', to: '/admin/balance' }],
        title: 'Manage Balance'
      }
    ]
  })
}
</script>
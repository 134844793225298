<template>
  <v-card>
    <v-card class="mb-3">
      <v-card-title>Update Invoice</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-select :items="types" label="Type"></v-select>
          <v-text-field label="Title" value="Desember 2021" required></v-text-field>
          <v-text-field label="Total" value="900000" required></v-text-field>
          <v-textarea label="Note"></v-textarea>
          <v-list flat subheader three-line>
            <v-subheader>Users</v-subheader>

            <v-list-item-group v-model="users" multiple active-class="">
              <v-list-item v-for="(user, index) in listUsers" :key="index">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ user }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <template v-if="active">
                        <v-text-field v-model="firstname" label="Total"> </v-text-field>
                      </template>
                      <template v-else>Check first</template>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-btn color="primary" class="mr-4"> Update </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    types: ['Wifi', 'Listrik', 'Sampah'],
    items: [
      {
        status: 'in',
        title: '20/12/2021 | Listrik Desember 2021',
        total: 'Rp 360.000'
      },
      {
        status: 'out',
        title: '20/12/2021 | Listrik Desember 2021',
        total: 'Rp 360.000'
      },
      {
        status: 'in',
        title: '20/12/2021 | Listrik Desember 2021',
        total: 'Rp 360.000'
      },
      {
        status: 'in',
        title: '20/12/2021 | Listrik Desember 2021',
        total: 'Rp 360.000'
      }
    ],
    users: [],
    listUsers: ['Reza', 'Razin', 'Syafiq', 'Yoga']
  })
}
</script>
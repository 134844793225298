<template>
  <v-card>
    <v-card class="mx-auto">
      <v-card-title>Create Type</v-card-title>
      <v-card-text>
        <v-form ref="form" @submit.prevent="onSend">
          <v-text-field label="Name" v-model="name" required></v-text-field>
          <v-btn color="primary" class="mr-4" type="submit"> Create </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card class="mx-auto">
      <v-card-title>List Type</v-card-title>
      <v-card-text>
        <v-list two-line>
          <v-list-item v-for="(type, index) in types" :key="index">
            <v-list-item-avatar>
              <v-icon class="grey lighten-1" dark> mdi-format-list-bulleted-type </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="type.name"></v-list-item-title>

              <v-list-item-subtitle v-text="type.invoices_count"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <!-- <v-btn icon>
                <v-icon color="grey lighten-1">mdi-delete</v-icon>
              </v-btn> -->
              <DialogDelete :id="type.id" :name="type.name" />
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-card>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import DialogDelete from '../../../components/dialog/DialogDeleteType.vue'

export default {
  components: {
    DialogDelete
  },
  data: () => ({
    name: ''
  }),
  computed: {
    ...mapState({
      types: (state) => state.type.types
    })
  },
  methods: {
    ...mapActions('type', ['listType', 'createType', 'deleteType']),
    onSend() {
      this.createType({ name: this.name })
      this.name = ''
    }
  },
  mounted() {
    this.listType()
  }
}
</script>
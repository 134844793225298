<template>
  <v-card>
    <v-card-title>List Invoices</v-card-title>
    <v-card-text>
      <ListInvoice />
    </v-card-text>
  </v-card>
</template>
<script>
import ListInvoice from '../../../components/ListInvoice.vue'

export default {
  components: {
    ListInvoice
  },
  data: () => ({
    types: ['Wifi', 'Listrik', 'Sampah'],
    items: [
      {
        status: 'in',
        title: '20/12/2021 | Listrik Desember 2021',
        total: 'Rp 360.000'
      },
      {
        status: 'out',
        title: '20/12/2021 | Listrik Desember 2021',
        total: 'Rp 360.000'
      },
      {
        status: 'in',
        title: '20/12/2021 | Listrik Desember 2021',
        total: 'Rp 360.000'
      },
      {
        status: 'in',
        title: '20/12/2021 | Listrik Desember 2021',
        total: 'Rp 360.000'
      }
    ]
  }),

}
</script>
<template>
  <v-card
    :to="'/admin/invoice/update-bill/' + id"
    :color="status == 'paid' ? 'light-blue darken-4' : 'light-blue darken-1'"
    dark
  >
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title class="text-h5" v-text="title"></v-card-title>

        <v-card-subtitle>{{ user }} | {{ formatNumberRupiah(total) }}</v-card-subtitle>
      </div>

      <v-avatar class="ma-5" tile>
        <v-icon x-large v-if="status == 'paid'">mdi-clipboard-check</v-icon>
        <v-icon x-large v-else>mdi-alert-box</v-icon>
      </v-avatar>
    </div>
  </v-card>
</template>

<script>
import { formatNumberRupiah } from '../helpers/helper'
export default {
  props: {
    status: String,
    title: String,
    total: String,
    user: String,
    id: Number
  },
  methods: {
    formatNumberRupiah
  }
}
</script>
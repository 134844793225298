<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-select
          :items="getTypeSelect"
          item-value="value"
          item-text="label"
          label="Type"
          solo
          v-model="type_id"
          @change="listListInvoice"
        ></v-select>
      </v-col>
      <v-col v-for="(item, i) in invoices" :key="i" cols="12">
        <card-invoice :name="item.name" :total="item.total" :user="item.bills.length" :id="item.id"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CardInvoice from './CardInvoice.vue'

export default {
  name: 'Home',

  components: {
    CardInvoice
  },

  data: () => ({
    type_id: ''
  }),
  computed: {
    ...mapState({
      invoices: (state) => state.invoice.invoices
    }),
    ...mapGetters('type', ['getTypeSelect'])
  },
  methods: {
    ...mapActions('invoice', ['listInvoice']),
    ...mapActions('type', ['listType']),
    listListInvoice() {
      this.listInvoice({ type_id: this.type_id })
    }
  },
  mounted() {
    this.listListInvoice()
    this.listType()
  }
}
</script>

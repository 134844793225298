<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon color="grey lighten-1">mdi-delete</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5"> Are you sure want delete {{ name }} type? </v-card-title>
        <v-card-text> Tindakan tidak tidak dapat dibatalkan </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false"> Disagree </v-btn>
          <v-btn color="red darken-1" text @click="agreeDelete"> Agree </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  props: {
    id: Number,
    name: String
  },
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    ...mapActions('type', ['deleteType']),
    agreeDelete() {
      this.deleteType(this.id)
      this.dialog = false
    }
  }
}
</script>
<template>
  <v-card :to="'/invoice/' + id" color="light-blue darken-2" dark>
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title class="text-h5" v-text="name"></v-card-title>

        <v-card-subtitle> {{ formatNumberRupiah(total) }} | {{ user }}</v-card-subtitle>
      </div>

      <v-avatar class="ma-5" tile>
        <v-icon x-large>mdi-receipt</v-icon>
      </v-avatar>
    </div>
  </v-card>
</template>

<script>
import { formatNumberRupiah } from '../helpers/helper'
export default {
  props: {
    id: Number,
    name: String,
    total: Number,
    user: Number
  },
  methods: {
    formatNumberRupiah
  }
}
</script>
<template>
  <v-card>
    <v-card class="mb-3">
      <v-card-title>Create Invoice</v-card-title>
      <v-card-text>
        <v-alert dense type="info" v-if="message"> {{ message }} </v-alert>
        <v-form ref="form" @submit.prevent="onSend">
          <v-select :items="types" label="Type" item-text="label" item-value="value" v-model="form.type_id"></v-select>
          <v-text-field label="Name" v-model="form.name" required></v-text-field>
          <v-text-field label="Total" v-model="form.total" required></v-text-field>
          <v-textarea label="Note" v-model="form.note"></v-textarea>
          <v-file-input multiple label="Attach Image" v-model="form.image"></v-file-input>
          <ListUserBillTotal />

          <v-btn color="primary" class="mr-4" type="submit"> Create </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>List Invoices</v-card-title>
      <v-card-text>
        <ListInvoice />
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import ListInvoice from '../../../components/ListInvoice.vue'
import ListUserBillTotal from '../../../components/ListUserBillTotal.vue'

export default {
  components: {
    ListInvoice,
    ListUserBillTotal
  },
  data: () => ({
    form: {},
    items: [
      {
        status: 'in',
        title: '20/12/2021 | Listrik Desember 2021',
        total: 'Rp 360.000'
      },
      {
        status: 'out',
        title: '20/12/2021 | Listrik Desember 2021',
        total: 'Rp 360.000'
      },
      {
        status: 'in',
        title: '20/12/2021 | Listrik Desember 2021',
        total: 'Rp 360.000'
      },
      {
        status: 'in',
        title: '20/12/2021 | Listrik Desember 2021',
        total: 'Rp 360.000'
      }
    ]
  }),
  methods: {
    ...mapActions('type', ['listType']),
    ...mapGetters('type', ['getTypes']),
    ...mapActions('invoice', ['createInvoice']),
    onSend() {
      const { type_id, name, total, note, image } = this.form
      this.createInvoice({ type_id, name, total, note, image })
      this.resetForm()
    },
    resetForm() {
      this.form = {
        type_id: '',
        name: '',
        total: '',
        note: '',
        image: null
      }
    }
  },
  computed: {
    ...mapState({
      message: (state) => state.invoice.message
    }),
    types() {
      return this.getTypes().map((item) => {
        return { label: item.name, value: item.id }
      })
    }
  },
  mounted() {
    this.listType()
    this.resetForm()
  }
}
</script>